import { useDispatch, useSelector } from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import { TablePagination } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { setPage, setRowsPerPage } from "../../slices/user";
import CheckBoxRow from "./Components/CheckBoxRow";
import Search from "../../utilities/Search";
import Row from "./Components/Row";
import NoDataFound from "../../assets/images/NoDataFound.jpg";
import "./index.scss";
const TableComponent = ({
  header,
  rows,
  className,
  tableName,
  isAction,
  isCollapsed,
  isNegotiation,
  isMySpace,
  isAuthorisation,
  allowed,
  sortBy,
  getLeaveStatus,
  leaveTypes,
  value,
  isPaging,
  setNotice,
  leaveID,
  AllPermissions,
  options,
  permission,
  userPermissions,
  userId,
  GroupPermissionsTable,
  AllPermissionGroups,
  reset,
  setReset,
  permsionPath,
  permissiongroupestatus,
  loading,

  noDataClass,
}) => {
  const dispatch = useDispatch();
  const { rowsPerPage, page } = useSelector((state) => state.user);
  const Rows = Search(rows, value, tableName);

  useEffect(() => {
    if (leaveID) {
      dispatch(setPage(0));
      Rows?.map((row, index) =>
        row._id === leaveID
          ? dispatch(setPage(Math.ceil((index + 1) / rowsPerPage) - 1))
          : ""
      );
    }
  }, [leaveID, Rows]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value)));
    dispatch(setPage(0));
  };

  return (
    <div className="table-component">
      <TableContainer className="table-container">
        {Rows?.length === 0  ? (
          <div className={`no-data-found ${noDataClass ? noDataClass : ""}`}>
            <img src={NoDataFound} alt="no data " />
          </div> 
         )
       : (
        <Table stickyHeader aria-label="sticky table" className="table-content">
          <TableHead>
            <TableRow className="table-row">
              {header?.map((el, key) => (
                <TableCell key={key}>{el.thead}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableName === "PermissionTable" ? (
              rows?.map((row, rowIndex) => {
                return (
                  <>
                    <CheckBoxRow
                      AllPermissionGroups={AllPermissionGroups}
                      tableName="PermissionTable"
                      row={row}
                      className={className}
                      options={options}
                      AllPermissions={AllPermissions}
                      rowIndex={rowIndex}
                      permission={permission}
                      userPermissions={userPermissions}
                      userId={userId}
                      GroupPermissionsTable={GroupPermissionsTable}
                      reset={reset}
                      setReset={setReset}
                      permsionPath={permsionPath}
                      permissiongroupestatus={permissiongroupestatus}
                    />
                  </>
                );
              }))
            :
                 (rowsPerPage > 0 ?
                   Rows?.slice( page * rowsPerPage,   page * rowsPerPage + rowsPerPage )
                  : Rows)?.map((row, key) => {
                    return (
                      <Row
                        page={page}
                        key={key}
                        row={row}
                        index={key}
                        tableName={tableName}
                        isAction={isAction}
                        isNegotiation={isNegotiation}
                        isMySpace={isMySpace}
                        isAuthorisation={isAuthorisation}
                        isCollapsed={isCollapsed}
                        allowed={allowed}
                        sortBy={sortBy}
                        getLeaveStatus={getLeaveStatus}
                        leaveTypes={leaveTypes}
                        setNotice={setNotice}
                        leaveID={leaveID}
                      />
                    );
                  }) 
            }
          </TableBody>
        </Table>
        )}
      </TableContainer>

      {isPaging && Rows?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={Rows?.length ? Rows?.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            inputProps: {
              "aria-label": "page number",
            },
          }}
        />
      )}
    </div>
)}

export default TableComponent;
